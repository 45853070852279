.event-img
{
	position:relative;
	width:350px;
	height:350px;
	border-radius: 10px;
	box-shadow: 0px 0px 5px gray;
	/* border:1px solid #000; */
}
.event-img img
{
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius: 10px;

	/* border:1px solid #000; */
}
.card__header{
	margin-bottom: 1rem;
	font-family: 'Playfair Display', serif;
	font-size:18px;
	font-weight:600;
  }