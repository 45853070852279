.banner-one-content{
    position: absolute;
    top: 0;
    /* border: 1px solid red; */
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
 
}
.banner-one-card{
    position: relative;
}

.banner-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    margin-top: 52px;
}
.banner-text{
    background: rgb(255, 255, 255, 0.7);
    display: inline;
    font-size: 53px;
    padding: 0px 11px;
    box-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
    display: inline-block;
    font-size: 70px;
    line-height: 80px;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 0 10px;
    text-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
    border-radius: 5px;
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    text-transform: uppercase;
}
@media(max-width:990px) and (min-width:554px)
{
    .banner-text{
        background: rgb(255, 255, 255, 0.7);
        display: inline;
        /* font-size: 82px; */
        padding: 0px 11px;
        box-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        display: inline-block;
        font-size: 40px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 10px 10px;
        text-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        -webkit-animation-duration: 1.4s;
        animation-duration: 1.4s;
        text-transform: uppercase;
    }
    .banner-button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        margin-top: 0;
    }
    
}
@media(max-width:554px) and (min-width:511px)
{
    .banner-text{
        background: rgb(255, 255, 255, 0.7);
        display: inline;
        /* font-size: 82px; */
        padding: 0px 11px;
        box-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        display: inline-block;
        font-size: 40px;
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 10px 10px;
        text-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        -webkit-animation-duration: 1.4s;
        animation-duration: 1.4s;
        text-transform: uppercase;
    }
    .banner-button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        margin-top: 0;
    }
    
}
@media(max-width:510px) and (min-width:150px)
{
    .banner-text{
        background: rgb(255, 255, 255, 0.7);
        display: inline;
        /* font-size: 82px; */
        padding: 0px 11px;
        box-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        display: inline-block;
        font-size: 20px;
        line-height: 10px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 10px 10px;
        text-shadow: 3px 0 7px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        -webkit-animation-duration: 1.4s;
        animation-duration: 1.4s;
        text-transform: uppercase;
    }
    .banner-button{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 60px;
        margin-top: 0;
    }
    
}
.svg-icons{
    font-size: 37px;
    color: #fff;
}

.icon-style.pulm i:after {
    background: #92278f;
}
.activities-icon{
    font-size: 60px;

}

.testimonial-card{
    border-radius: 5px;
    padding: 20px 20px;
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color:#fff;
    text-align: center;
}

.left-testimonial{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
