/* .container {
    width: 100%;
    max-width: 960px;
    padding: 0 15px;
    margin: 150px auto;
} */
.tit-content{
    padding-top: 28px;
}
.progressbar-item {
    margin-bottom: 28px;
}

.progress-bar {
    margin: -34px 0 10px;
    overflow: visible !important;
    background: transparent;
}

.progress-number {
    display: flex;
    justify-content: end;
    padding-bottom: 30px;
    position: relative;
    margin: 5px 0;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    line-height: 15px;
    font-weight: 400;
    color: #2e2e2e;
}

.progress-title {
    z-index: 100;
    /* font-size: 15px; */
    font-weight: 400;
    margin: 0;
    text-align: start;
}
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    padding-top: 45px;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);
}
.progress-number-mark {
    font-family: var(--progress-font, "Open Sans", sans-serif);
    font-weight: var(--fw-500);
    font-size: var(--fs-16);
    line-height: 1;
    padding: 8px 8px 7px;
    border-radius: 3px;
    color: var(--progress-number-color, #fff);
    margin-bottom: 4px;
    border-radius: 3px;
    background: var(--progress-number-bg, #5f5f5f);
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
}

.down-arrow {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid var(--progress-number-bg, #5f5f5f);
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
}

.progress-bg {
    height: 6px;
    background: var(--progressbar-bg, #f0f0f0);
    overflow: hidden;
    border-radius: 6px;
}

.progress-fill {
    height: 6px;
    background: var(--progressbar-fill, linear-gradient(to right, #B464FF 0%, #3C64EB 100%));
    width: 0%;
    border-radius: 6px;
}


#jquery-script-menu {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    border-top: 5px solid #316594;
    background: #fff;
    -moz-box-shadow: 0 2px 3px 0 rgba(0,0,0,.16);
    -webkit-box-shadow: 0 2px 3px 0 rgba(0,0,0,.16);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,.16);
    z-index: 999999;
    padding: 10px 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.jquery-script-center {
    max-width: 960px;
    margin: 0 auto
}

.jquery-script-center ul {
    width: 212px;
    float: left;
    line-height: 45px;
    margin: 0;
    padding: 0;
    list-style: none
}

.jquery-script-center a {
    text-decoration: none
}

.jquery-script-ads {
    max-width: 728px;
    height: 90px;
    float: right
}

.jquery-script-clear {
    clear: both;
    height: 0
}

#carbonads {
    display: block;
    overflow: hidden;
    max-width: 728px;
    position: relative;
    font-size: 22px;
    box-sizing: content-box
}

#carbonads>span {
    display: block
}

#carbonads a {
    color: #4078c0;
    text-decoration: none
}

#carbonads a:hover {
    color: #3664a3
}

.carbon-wrap {
    display: flex;
    align-items: center
}

.carbon-img {
    display: block;
    margin: 0;
    line-height: 1
}

.carbon-img img {
    display: block;
    height: 90px;
    width: auto
}

.carbon-text {
    display: block;
    padding: 0 1em;
    line-height: 1.35;
    text-align: left
}

.carbon-poweredby {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 6px 10px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 600;
    font-size: 8px;
    border-top-left-radius: 4px;
    line-height: 1;
    color: #aaa!important
}

@media only screen and (min-width: 320px) and (max-width:759px) {
    .carbon-text {
        font-size:14px
    }
}

@media only screen and (max-width: 1023px) {
    .jquery-script-ads {
        display:none
    }
}
@media (max-width: 768px) {
    .kid-imgg {
        
        width: 162px !important;
        margin-left: -56px !important;
    }
}
@media (max-width: 1024px) {
    .kid-imgg {
        width: 200px;
        margin-left: -29px;
    }
}
@media (max-width: 425px) {
    .kid-imgg img {
        width: 100%;
        display: none;

    }}
@media (max-width: 1024px) {
    .col-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
        padding: 0 0 118px;
    }
}
@media (max-width: 500px) {
    .count-bar {
        font-weight: 700;
        text-align: end;
        display: flex !important;
        justify-content: center !important;
        font-size: 25px;
    }
    .progress-title {
        z-index: 100;
        /* font-size: 15px; */
        font-weight: 400;
        margin: 0;
        display: flex;
        justify-content: center;
        text-align: end;
    }
}



.count-bar{
    font-weight: 700;
    text-align: end;
    font-size: 25px;
}

.kid-imgg{
    width: 200px;
    padding-top: 250px;
}
.kid-imgg img{
    width: 100%;
}

.bgc-deg{
    background:
    url("../Assert//blow-96.jpeg");
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position:center !important;
  overflow: hidden;

}




