:where(.css-dev-only-do-not-override-1qfezbu).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: green !important;
}
:where(.css-dev-only-do-not-override-1qfezbu).ant-tabs .ant-tabs-tab{
    font-size: 18px !important;
    font-weight: 500;
}

.vision-mission-sec{
    /* background: linear-gradient(to right, rgba(255, 255, 255, .3), rgba(255, 255, 255, 1)), url('../Assert/jpeg-optimizer_aboutus3.jpg'); */
    background: linear-gradient( to right, rgb(255,255,255, 0.3),rgb(255,255,255, 1)), url('../Assert/jpeg-optimizer_aboutus3.jpg');

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* background-image: url('../Assert/jpeg-optimizer_aboutus3.jpg'); */
    /* background-color: rgb(255,255,255,0.9); */
    /* position: relative; */
}
.vision-mission-contain::after{
content: '';
}
.para-vission-mission{
    font-size: 16px;
}

/*-------------------------------------
    07. About Us
-------------------------------------*/
.rs-about .about-tab.extra-padding {
    padding-top: 25px;
  }
  .rs-about .about-tab .sec-why .author-sign .name {
    margin-bottom: 15px;
  }
  .rs-about .about-tab .sec-why .author-sign .name span {
    font-weight: 700;
  }
  .rs-about .about-tab .nav .nav-item {
    margin-right: 30px;
  }
  .rs-about .about-tab .nav .nav-item .nav-link {
    padding: 12px 30px;
    line-height: 1;
    font-size: 18px;
    border-bottom: 1px solid #f1eff1;
    border-radius: 0;
    position: relative;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active {
    background: #92278f;
    color: #ffffff;
    border-radius: 3px;
    border-bottom: none;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-pulm {
    background: #92278f;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-pulm:after {
    border-top-color: #92278f;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-orange {
    background: #fe6500;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-orange:after {
    border-top-color: #fe6500;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-blue {
    background: #1eaee3;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active.kinder-blue:after {
    border-top-color: #1eaee3;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.active:after {
    content: "";
    border-top: 15px solid;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease 0s;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.kinder-pulm {
    border-color: #92278f;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.kinder-orange {
    border-color: #fe6500;
  }
  .rs-about .about-tab .nav .nav-item .nav-link.kinder-blue {
    border-color: #1eaee3;
  }
  .rs-about .about-tab .nav .nav-item:last-child {
    margin-right: 0;
  }
  .rs-about .about-tab.style-2 .nav-item {
    margin-right: 10px;
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
  }
  .rs-about .about-tab.style-2 .nav-item:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 1px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-right: 46px solid #ffffff;
    border-bottom: 21px solid transparent;
    z-index: 1;
  }
  .rs-about .about-tab.style-2 .nav-item:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 1px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-left: 46px solid #ffffff;
    border-bottom: 21px solid transparent;
    z-index: 1;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link {
    position: unset;
    border: 1px solid;
    padding: 0 10px;
    font-size: 16px;
    height: 45px;
    line-height: 45px;
    background-color: #ffffff;
    transition: all 0.3s ease 0s;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active {
    border-radius: 0;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active:before {
    border-right: 46px solid;
    z-index: 9;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active:after {
    border-left: 46px solid;
    border-top: 23px solid transparent;
    border-bottom: 22px solid transparent;
    left: unset;
    right: 0;
    top: 0;
    border-right: unset;
    transform: unset;
    border-top-color: transparent !important;
    z-index: 9;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-pulm-title-bg {
    border-color: #92278f;
    background: #92278f;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-pulm-title-bg:before {
    border-right-color: #92278f;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-pulm-title-bg:after {
    border-left-color: #92278f;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-orange-title-bg {
    border-color: #fe6500;
    background: #fe6500;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-orange-title-bg:before {
    border-right-color: #fe6500;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-orange-title-bg:after {
    border-left-color: #fe6500;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-blue-title-bg {
    border-color: #1eaee3;
    background: #1eaee3;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-blue-title-bg:before {
    border-right-color: #1eaee3;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.active.kinder-blue-title-bg:after {
    border-left-color: #1eaee3;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-pulm-title-bg:before {
    border-right-color: #92278f;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-pulm-title-bg:after {
    border-left-color: #92278f;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-orange-title-bg:before {
    border-right-color: #fe6500;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-orange-title-bg:after {
    border-left-color: #fe6500;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-blue-title-bg:before {
    border-right-color: #1eaee3;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link.kinder-blue-title-bg:after {
    border-left-color: #1eaee3;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-right: 45px solid #92278f;
    border-bottom: 22px solid transparent;
  }
  .rs-about .about-tab.style-2 .nav-item .nav-link:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 23px solid transparent;
    border-left: 46px solid #92278f;
    border-bottom: 22px solid transparent;
  }

  .sec-why {
    margin-bottom: 50px;
  }
  .sec-why .section-title {
    margin-bottom: 15px;
  }
  .sec-why .section-title.inner {
    font-size: 30px;
  }
  .sec-why .section-title span {
    display: block;
    padding-bottom: 5px;
  }
  .sec-why .section-subtitle {
    position: relative;
    display: inline-block;
    color: #fe6500;
    padding: 0 40px;
    margin: 0;
  }
  .sec-why .section-subtitle:before,
  .sec-why .section-subtitle:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background: #fe6500;
  }
  .sec-why .section-subtitle:after {
    left: unset;
    right: 0;
  }
  .sec-why .section-subtitle.kinder-orange {
    color: #fe6500;
  }
  .sec-why .section-subtitle.kinder-orange:before,
  .sec-why .section-subtitle.kinder-orange:after {
    background: #fe6500;
  }
  .sec-why .section-subtitle.kinder-blue {
    color: #1eaee3;
  }
  .sec-why .section-subtitle.kinder-blue:before,
  .sec-why .section-subtitle.kinder-blue:after {
    background: #1eaee3;
  }
  .sec-why .section-subtitle.kinder-pulm {
    color: #92278f;
  }
  .sec-why .section-subtitle.kinder-pulm:before,
  .sec-why .section-subtitle.kinder-pulm:after {
    background: #92278f;
  }
  .sec-why .section-subtitle.kinder-yellow {
    color: #fda00f;
  }
  .sec-why .section-subtitle.kinder-yellow:before,
  .sec-why .section-subtitle.kinder-yellow:after {
    background: #fda00f;
  }
  .sec-why .section-subtitle.kinder-pink {
    color: #e72a52;
  }
  .sec-why .section-subtitle.kinder-pink:before,
  .sec-why .section-subtitle.kinder-pink:after {
    background: #e72a52;
  }
  .sec-why .section-subtitle.kinder-green {
    color: #07bcb6;
  }
  .sec-why .section-subtitle.kinder-green:before,
  .sec-why .section-subtitle.kinder-green:after {
    background: #07bcb6;
  }
  .sec-why .section-subtitle-right {
    position: relative;
    display: inline-block;
    color: #fe6500;
    padding: 0 40px 0 0;
    margin: 0;
  }
  .sec-why .section-subtitle-right:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 2px;
    background: #fe6500;
  }
  .sec-why .section-subtitle-right:after {
    left: unset;
    right: 0;
  }
  .sec-why .section-subtitle-right.kinder-orange {
    color: #fe6500;
  }
  .sec-why .section-subtitle-right.kinder-orange:after {
    background: #fe6500;
  }
  .sec-why .section-subtitle-right.kinder-blue {
    color: #1eaee3;
  }
  .sec-why .section-subtitle-right.kinder-blue:after {
    background: #1eaee3;
  }
  .sec-why .section-subtitle-right.kinder-pulm {
    color: #92278f;
  }
  .sec-why .section-subtitle-right.kinder-pulm:after {
    background: #92278f;
  }
  .sec-why .section-subtitle-right.kinder-yellow {
    color: #fda00f;
  }
  .sec-why .section-subtitle-right.kinder-yellow:after {
    background: #fda00f;
  }
  .sec-why .section-subtitle-right.kinder-pink {
    color: #e72a52;
  }
  .sec-why .section-subtitle-right.kinder-pink:after {
    background: #e72a52;
  }
  .sec-why .section-subtitle-right.kinder-green {
    color: #07bcb6;
  }
  .sec-why .section-subtitle-right.kinder-green:after {
    background: #07bcb6;
  }
  .sec-why .sec-subtitle {
    font-size: 16px;
    line-height: 1.2;
  }
  .sec-why .section-desc {
    margin: 15px 0
  }
  .about-us-youtbe-link{
    width: 100%;
    /* min-height: 150px; */
    height: 300px;
    margin-bottom: 20px;
  }