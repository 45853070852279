*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
body div{
    margin: 0;
    padding: 0;
    border: 0;
}