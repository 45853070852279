/* .bg-body-tertiary{
    height: 10vh;
} */

@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse{
        justify-content: end;
    }
}

.navbar-collapse {
    /* background: #fff; */
    padding: 7px 13px;
    z-index: 1000;
}
@media (max-width:991px){
    .navbar-collapse{
        background-color: #fff;
    }
}
.sticky_nav1{
    position: sticky;
    top: 0;
    background: black;
    z-index: 1060;
    animation: stickyHead 0.3 ease forwards;

}
@keyframes stickyHead{
    0%{
        transform: translate(--100px);
    }
    100%{
        transform: translate(0px);
    }
}
.nav-logo{
    max-width: 250px;
    transform: scale(1);
    height: 100%;
}
@media(max-width:1004px) and (min-width:554px)
{
    .nav-logo{
        max-width: 250px;
        transform: scale(1);
        height: 100%;
    }
}
.navbar-expand-lg .navbar-nav{
    text-transform: uppercase;
}